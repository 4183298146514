import { ChainId, nativeAssetSupertokensByNetwork } from '@/config/networks'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { useCallback } from 'react'
import { Address } from 'viem'
import { useChainId } from 'wagmi'
import { useTokenList } from './useTokenList'

export const useTokenRelations = () => {
  const { all } = useTokenList()
  const userChainId = useChainId() as ChainId

  const isNativeAssetSuperToken = useCallback(
    (t?: Address): boolean => {
      if (t?.toLowerCase() === nativeAssetSupertokensByNetwork[userChainId]?.superToken.address.toLowerCase()) {
        return true
      }

      return false
    },
    [userChainId]
  )

  const isNativeAssetToken = useCallback(
    (t?: Address): boolean => {
      if (t?.toLowerCase() === nativeAssetSupertokensByNetwork[userChainId]?.underlyingToken.address.toLowerCase()) {
        return true
      }

      return false
    },
    [userChainId]
  )

  const getUnderlyingTokenOf = useCallback(
    (t?: Address) => {
      if (isNativeAssetSuperToken(t)) {
        return nativeAssetSupertokensByNetwork[userChainId]?.underlyingToken
      }

      const token = all.find(({ address }) => address === t)

      if (token?.tags?.includes('underlying')) {
        return token
      }

      const underlyingToken = all.find(
        ({ address }) =>
          token?.extensions?.superTokenInfo.type === 'Wrapper' &&
          address === token?.extensions.superTokenInfo.underlyingTokenAddress
      )

      return underlyingToken
    },

    [all, userChainId, isNativeAssetSuperToken]
  )

  const getSuperTokenOf = useCallback(
    (t?: Address): SuperTokenInfo | undefined => {
      if (isNativeAssetToken(t)) {
        return nativeAssetSupertokensByNetwork[userChainId]?.superToken
      }

      const token = all.find(({ address }) => address === t)

      if (token?.tags?.includes('supertoken')) {
        return token
      }

      const superToken = all.find(
        superToken =>
          token?.tags?.includes('underlying') &&
          superToken?.extensions?.superTokenInfo.type === 'Wrapper' &&
          token?.address === superToken?.extensions.superTokenInfo.underlyingTokenAddress
      )

      return superToken
    },
    [all, userChainId, isNativeAssetToken]
  )

  return {
    isNativeAssetToken,
    isNativeAssetSuperToken,
    getSuperTokenOf,
    getUnderlyingTokenOf
  }
}
