import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { Metadata, Viewport } from 'next'
import { IconType } from 'react-icons'
import { Hex, zeroAddress } from 'viem'
import bg from '../public/background.svg'
import bgNS from '../public/bg-ns.svg'
import bgNWSE from '../public/bg-nwse.svg'
import bgSWNE from '../public/bg-swne.svg'
import { Icons } from './components/ui/icons'
import { siteConfig } from './config/site'
import { FlowRate, Position, Staking, Torex } from './types'
import Resources from './types/resources'

export const EMPTY_BYTES = '' as Hex

export const viewport: Viewport = {
  themeColor: [
    { media: '(prefers-color-scheme: light)', color: 'white' },
    { media: '(prefers-color-scheme: dark)', color: 'black' }
  ],
  userScalable: false
}

export const metadata: Metadata = {
  metadataBase: new URL('https://app.superboring.xyz'),
  title: {
    default: siteConfig.name,
    template: `%s | ${siteConfig.name}`
  },
  description: siteConfig.description,
  applicationName: siteConfig.name,
  keywords: ['SuperBoring', 'DEFI', 'DCA', 'Dollar cost averageing'],
  authors: [
    {
      name: 'Superfluid',
      url: 'https://superfluid.org/'
    }
  ],
  creator: 'Superfluid Team',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: siteConfig.url,
    title: siteConfig.name,
    description: siteConfig.description,
    siteName: siteConfig.name,
    images: [{ url: '/og-image.png', alt: siteConfig.name }]
  },
  twitter: {
    card: 'summary_large_image',
    title: siteConfig.name,
    description: siteConfig.description,
    images: ['/og-image.png'],
    creator: 'Superfluid Team'
  },
  icons: {
    icon: '/favicon.ico',
    shortcut: '/favicon-16x16.png',
    apple: '/apple-touch-icon.png',
    other: [
      {
        url: 'favicon-16x16.png',
        sizes: '16x16',
        type: 'image/png',
        rel: 'icon'
      },
      {
        url: 'favicon-32x32.png',
        sizes: '32x32',
        type: 'image/png',
        rel: 'icon'
      }
    ]
  },
  // To support splash screen on iOS
  appleWebApp: true
}

export type NavKey = 'dca' | 'markets' | 'portfolio' | 'staking' | 'referrals'

export type NavItem = {
  name: NavKey
  tkey: keyof Resources['menu']
  path: string
  Icon: IconType
  image: string
  className?: string
  onClick?: (_: unknown) => void
}

export const PROTOCOL_FEE_MULTIPLIER = 0.005

export const nav: Record<NavKey, NavItem> = {
  dca: {
    name: 'dca',
    tkey: 'dca',
    path: '/dca',
    image: bg.src,
    Icon: Icons.dollarSign
  },
  markets: {
    name: 'markets',
    tkey: 'markets',
    path: '/markets',
    image: bg.src,
    Icon: Icons.billing
  },
  portfolio: {
    name: 'portfolio',
    tkey: 'portfolio',
    path: '/portfolio',
    image: bgSWNE.src,
    Icon: Icons.activity
  },
  staking: {
    name: 'staking',
    tkey: 'staking',
    path: '/staking',
    image: bgNS.src,
    Icon: Icons.beef
  },
  referrals: {
    name: 'referrals',
    tkey: 'referrals',
    path: '/referrals',
    image: bgNWSE.src,
    Icon: Icons.users
  }
} as const

export enum BatchOperation {
  OPERATION_TYPE_ERC20_APPROVE = 1,
  OPERATION_TYPE_ERC20_TRANSFER_FROM = 2,
  OPERATION_TYPE_ERC777_SEND = 3,
  OPERATION_TYPE_ERC20_INCREASE_ALLOWANCE = 4,
  OPERATION_TYPE_ERC20_DECREASE_ALLOWANCE = 5,
  OPERATION_TYPE_SUPERTOKEN_UPGRADE = 101,
  OPERATION_TYPE_SUPERTOKEN_DOWNGRADE = 102,
  OPERATION_TYPE_SUPERFLUID_CALL_AGREEMENT = 201,
  OPERATION_TYPE_SUPERFLUID_CALL_APP_ACTION = 202
}

export const mockToken: SuperTokenInfo = {
  address: zeroAddress,
  symbol: '',
  name: '',
  decimals: 0,
  chainId: 0,
  extensions: {
    superTokenInfo: { type: 'Pure' }
  },
  tags: []
}

export const mockTorex: Torex = {
  address: zeroAddress,
  inboundToken: mockToken,
  outboundToken: mockToken,
  distribuitionPool: zeroAddress,
  rewardTokenPool: zeroAddress,
  feeDistributionPool: zeroAddress,
  config: {
    controller: zeroAddress,
    inToken: zeroAddress,
    outToken: zeroAddress,
    observer: zeroAddress,
    discountFactor: 0n,
    maxAllowedFeePM: 0n,
    outTokenDistributionPoolScaler: 0n,
    twapScaler: 0n
  },
  minimumInTokenFlowRate: 0n
}

export const mockPosition: Position = {
  torex: mockTorex,
  createdAtTimestamp: 0,
  updatedAtTimestamp: 0,
  totalStreamed: 0n,
  totalReceived: 0n,
  flowRate: 0n,
  netBackAdjustments: 0n
}

export const defaultFlowRate: FlowRate = {
  amountEth: '0',
  period: 'month'
}

export const mockStaking: Staking = {
  torex: mockTorex,
  userStaked: 0n,
  totalStaked: 0n,
  totalInFlowrate: 0n,
  totalFeeDistributionFlowrate: 0n,
  userFeeDistributionFlowRate: 0n,
  totalFeeReceivedByUser: 0n,
  totalRewardFlowrate: 0n,
  userRewardFlowrate: 0n,
  rewardEmissionRate: 0n
}

export const EMISSION_TREASURY_INITIAL_BALANCE = 5n * 10n ** 6n * 10n ** 18n

export const BLOCK_CONFIRMATIONS = 1

export const tableDefaults = {
  filterFns: {
    filterByToken: () => true
  },
  sortingFns: {
    sortByVolumeFiat: () => 0,
    sortByBoringRewards: () => 0,
    sortByBoringStaked: () => 0,
    sortByStakingRewards: () => 0
  }
}

export enum DCAWidgetMode {
  SHORT = 'short',
  DEFAULT = 'default'
}
