'use client'

import { NavKey, nav } from '@/constants'
import { cn } from '@/utils'
import { usePathname } from 'next/navigation'
import { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import bg from '../../public/background.svg'
import noise from '../../public/noise.png'

type CompositeBackgroundType = {
  absolute?: boolean
}

export const CompositeBackground: FC<CompositeBackgroundType> = ({ absolute }) => {
  const activePath = usePathname()

  useEffect(() => {
    const dynBg = document.querySelector<HTMLDivElement>('#dynamic-background')
    const activeMenu = nav[activePath.replace(/^\/en\/|\/en$/, '') as NavKey]
    if (dynBg) {
      dynBg.style.scale = '1.15'
      dynBg.style.backgroundImage = `url(${activeMenu?.image ?? bg.src})`
    }
  }, [activePath])

  return createPortal(
    <>
      <div className={cn('w-screen h-screen top-0 -z-10 opacity-50 overflow-hidden', absolute ? 'absolute' : 'fixed')}>
        <div id='dynamic-background' className={cn('absolute w-full h-full bg-cover bg-[center_-30px] b')} />
      </div>

      <div
        className={cn('w-screen h-screen top-0 bg-cover -z-30 opacity-20', absolute ? 'absolute' : 'fixed')}
        style={{
          backgroundImage: `url('${noise.src}')`
        }}
      />
      <div
        className={cn('w-screen h-screen top-0 bg-cover -z-20 opacity-80', absolute ? 'absolute' : 'fixed')}
        style={{
          backgroundImage: 'radial-gradient(circle, rgba(0,0,0,0.2) 20%,  rgba(0,0,0,.90) 50%, rgba(0,0,0,1) 90%)'
        }}
      />
    </>,
    document.querySelector('body')!
  )
}
